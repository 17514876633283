<template>
  <router-view></router-view>
</template>

<script>
import { firebase } from "@/firebase";

export default {
  created: function () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            if (idTokenResult.claims.allow_access) {
              this.$store.commit("setUser", {
                uid: user.uid,
                name: user.displayName,
              });
              this.$store.dispatch("bindPMedIca");
              this.$store.dispatch("bindMMedIca");
            } else this.clearUser();
          })
          .catch(() => {
            if (this.$store.state.user) {
              this.$store.dispatch("bindPMedIca");
              this.$store.dispatch("bindMMedIca");
            }
          });
      } else this.clearUser();
    });
  },
  methods: {
    clearUser() {
      this.$store.commit("setUser", null);
      if (
        !["login", "logout", "nofound", "noaccess", "nopersistence"].includes(
          this.$route.name
        )
      )
        this.$router.push({ name: "login" });
    },
  },
};
</script>
