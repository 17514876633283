import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import createPersistedState from "vuex-persistedstate";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
import App from "./App.vue";
import routes from "./routes/routes";
import { firebase } from "./firebase";
import "./registerServiceWorker";

//https://github.com/vuematerial/vue-material/issues/2285
Vue.config.errorHandler = (err) => {
  if (err.message !== "Cannot read property 'badInput' of undefined") {
    console.error(err);
  }
};

//Serialize option to customise the data that is set to the vue instance
const serialize = (snapshot) => {
  return Object.defineProperties(snapshot.data(), {
    hasPendingWrites: {
      value: snapshot.metadata.hasPendingWrites,
    },
  });
};

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(Vuex);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkActiveClass: "nav-item active",
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (to.name !== "login" && requiresAuth && !store.state.user)
    next({ name: "login" });
  else if (to.name !== "nopersistence" && !store.state.firebase_persistence)
    next({ name: "nopersistence" });
  else if (to.name === "medica_det" && !store.state.medicadet?.[to.params.uuid])
    next({ name: "medica" });
  else next();
});

const store = new Vuex.Store({
  state: {
    firebase_persistence: true,
    user: {},
    medicadet: {},
    medicaini: {
      lugar_inicio: null,
      placa_patente: null,
      servicio: null,
      tarjeta_bip: null,
      unidad_negocio: null,
    },
    p_mediciones_ica: [],
    m_mediciones_ica: [],
  },
  getters: {
    getIcaDetById: (state) => (payload) => {
      if (payload.param)
        return state.medicadet?.[payload.uuid]?.[payload.param];
      else
        return {
          uuid: state.medicadet?.[payload.uuid]?.uuid,
          fecha_inicio_timestamp:
            state.medicadet?.[payload.uuid]?.fecha_inicio_timestamp,
          placa_patente: state.medicadet?.[payload.uuid]?.placa_patente,
          servicio: state.medicadet?.[payload.uuid]?.servicio,
          estandar: state.medicadet?.[payload.uuid]?.estandar,
          unidad_negocio: state.medicadet?.[payload.uuid]?.unidad_negocio,
          tipo_veh: state.medicadet?.[payload.uuid]?.tipo_veh,
        };
    },
    getIcaIni: (state) => (payload) => {
      return state.medicaini?.[payload.param];
    },
  },
  plugins: [
    createPersistedState({
      paths: [
        "user",
        "medicadet",
        "medicaini",
        "p_mediciones_ica",
        "m_mediciones_ica",
      ],
    }),
  ],
  //https://vuejs.org/v2/guide/reactivity.html#For-Objects
  //https://stackoverflow.com/questions/63785843/getter-not-reactive-when-updating-the-property-of-an-object-in-vuex
  //Se debe reemplazar todo el objeto state.medica para que lo que ingrese de forma posterior sea reactivo
  //State medica queda inmutable, no siendo reactivo

  mutations: {
    setUser: (state, payload) => {
      state.user = payload;
    },
    setpersistence: (state, payload) => {
      state.firebase_persistence = payload;
    },
    setIcaDet: (state, payload) => {
      state.medicadet[payload.uuid][payload.param] = payload.value;
    },
    initIcaDet: (state, payload) => {
      if (!state.medicadet[payload.uuid]) {
        Vue.set(state.medicadet, [payload.uuid], {
          uuid: payload.uuid,
          fecha_inicio_timestamp: payload.fecha_inicio_timestamp,
          placa_patente: payload.placa_patente,
          servicio: payload.servicio,
          estandar: payload.estandar,
          tipo_veh: payload.tipo_veh,
          unidad_negocio: payload.unidad_negocio,
          A01: false,
          A02: false,
          A03: false,
          A04: false,
          A05: false,
          A06: false,
          A07: false,
          A08: false,
          A09: false,
          A10: false,
          A11: false,
          A12: false,
          A13: false,
          A14: false,
          A15: false,
          A16: false,
          A17: false,
          A18: false,
          A01C: [],
          A02C: [],
          A03C: [],
          A04C: [],
          A05C: [],
          A06C: [],
          A07C: [],
          A08C: [],
          A09C: [],
          A10C: [],
          A11C: [],
          A12C: [],
          A13C: [],
          A14C: [],
          comentarios: null,
          lugar_termino: null,
        });
      }
    },
    clearIcaDet: (state, payload) => {
      delete state.medicadet[payload.uuid];
    },
    setIcaIni: (state, payload) => {
      state.medicaini[payload.param] = payload.value;
    },
    clearIcaIni: (state) => {
      state.medicaini = {
        lugar_inicio: null,
        placa_patente: null,
        servicio: null,
        tarjeta_bip: null,
      };
    },
    ...vuexfireMutations,
  },
  actions: {
    bindPMedIca: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "p_mediciones_ica",
        firebase
          .firestore()
          .collection("parametros")
          .doc("mediciones_ica"),
        { reset: false, serialize: serialize, wait: true, maxRefDepth: 0 }
      );
    }),
    bindMMedIca: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "m_mediciones_ica",
        firebase
          .firestore()
          .collection("mediciones_ica")
          .where(
            "fecha_inicio_timestamp",
            ">=",
            Date.now() / 1000 - 30 * 24 * 60 * 60
          )
          .where("user_uid", "==", store.state.user.uid)
          .orderBy("fecha_inicio_timestamp", "desc"),
        { reset: false, serialize: serialize, wait: true, maxRefDepth: 0 }
      );
    }),
    medicaAdd: firestoreAction((context, payload) => {
      return firebase
        .firestore()
        .collection("mediciones_ica")
        .doc(payload.uuid)
        .set(payload);
    }),
    medicaParam: firestoreAction((context, payload) => {
      return firebase
        .firestore()
        .collection("parametros")
        .doc("mediciones_ica")
        .update({
          ppu_servicio: firebase.firestore.FieldValue.arrayUnion(
            payload.placa_patente.concat(payload.servicio)
          ),
        });
    }),
    medicaDet: firestoreAction((context, payload) => {
      return firebase
        .firestore()
        .collection("mediciones_ica")
        .doc(payload.uuid)
        .update(payload);
    }),
  },
});

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .then(() => {
    store.commit("setpersistence", true);
  })
  .catch(() => {
    store.commit("setpersistence", false);
    if (router.history.current.name !== "nopersistence")
      router.push({ name: "nopersistence" });
  });

new Vue({
  router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");
