// Notifications plugin. Used on Notifications page
import Notifications from "@/components/NotificationPlugin";
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from "@/components/SidebarPlugin";

import {
  MdList,
  MdIcon,
  MdCard,
  MdTable,
  MdRipple,
  MdButton,
  MdToolbar,
  MdContent,
  MdField,
  MdMenu,
  MdCheckbox,
} from "vue-material/dist/components";

// asset imports
import "vue-material/dist/vue-material.min.css";
import "./assets/scss/material-dashboard.scss";

export default {
  install(Vue) {
    Vue.use(MdList);
    Vue.use(MdIcon);
    Vue.use(MdCard);
    Vue.use(MdTable);
    Vue.use(MdRipple);
    Vue.use(MdButton);
    Vue.use(MdToolbar);
    Vue.use(MdContent);
    Vue.use(MdField);
    Vue.use(MdMenu);
    Vue.use(MdCheckbox);
    Vue.use(SideBar);
    Vue.use(Notifications);
  },
};
