import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCl-CMq9gP8-t6_6GAMYUYJwY7zv6tBAN8",
  authDomain: "medevasion-79ed1.firebaseapp.com",
  databaseURL: "https://medevasion-79ed1.firebaseio.com",
  projectId: "medevasion-79ed1",
  storageBucket: "medevasion-79ed1.appspot.com",
  messagingSenderId: "1012696411986",
  appId: "1:1012696411986:web:7e681b34dd46ef814b148b",
  measurementId: "G-4T5H2CMXVX",
};
firebase.initializeApp(firebaseConfig);

export { firebase };
