const medicaPages = {
  path: "/",
  component: () => import("@/pages/layout/dashboard/DashboardLayout.vue"),
  children: [
    {
      path: "home",
      name: "home",
      component: () => import("@/pages/home.vue"),
      meta: {
        requiresAuth: true,
        desc: "Inicio",
      },
    },
    {
      path: "medica",
      name: "medica",
      component: () => import("@/pages/medica.vue"),
      meta: {
        requiresAuth: true,
        desc: "Medición ICA",
      },
    },
    {
      path: "medica/add",
      name: "medica_add",
      component: () => import("@/pages/medica_add.vue"),
      meta: {
        requiresAuth: true,
        desc: "Medición ICA",
      },
    },
    {
      path: "medica/det/:uuid",
      name: "medica_det",
      component: () => import("@/pages/medica_det.vue"),
      meta: {
        requiresAuth: true,
        desc: "Medición ICA",
      },
    },
  ],
};

const controlPages = {
  path: "/",
  component: () => import("@/pages/layout/auth/authlayout.vue"),
  children: [
    {
      path: "login",
      name: "login",
      component: () => import("@/pages/login.vue"),
    },
    {
      path: "noaccess",
      name: "noaccess",
      component: () => import("@/pages/noaccess.vue"),
    },
    {
      path: "nopersistence",
      name: "nopersistence",
      component: () => import("@/pages/nopersistence.vue"),
    },
    {
      path: "logout",
      name: "logout",
      component: () => import("@/pages/logout.vue"),
    },
    {
      path: "/:catchAll(.*)",
      name: "nofound",
      component: () => import("@/pages/nofound.vue"),
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "Home",
  },
  medicaPages,
  controlPages,
];

export default routes;
